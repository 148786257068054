import React from "react";
import { Link } from "react-router-dom";
import { BsLinkedin, BsGithub, BsYoutube, BsInstagram } from "react-icons/bs";
import newsletter from "../images/newsletter.png";
const Footer = () => {
  return (
    <>
    <footer className="py-4">
      <div className="container-xxl">
        <div className="row align-items-center">
          {/* Newsletter Section */}
          <div className="col-12 col-md-5 mb-3 mb-md-0">
            <div className="footer-top-data d-flex gap-30 align-items-center flex-wrap">
              <img src={newsletter} alt="newsletter" />
              <h2 className="mb-0 text-white fs-5">Sign Up for Newsletter</h2>
            </div>
          </div>
          <div className="col-12 col-md-7">
            <div className="input-group">
              <input
                type="text"
                className="form-control py-1"
                placeholder="Your Email Address"
                aria-label="Your Email Address"
                aria-describedby="basic-addon2"
              />
              <span className="input-group-text p-2" id="basic-addon2">
                Subscribe
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  
    <footer className="py-4">
      <div className="container-xxl">
        <div className="row">
          {/* Contact Section */}
          <div className="col-12 col-md-4 mb-3 mb-md-0">
            <h4 className="text-white mb-4 fs-6">Contact Us</h4>
            <div>
              <address className="text-white fs-6">
                Hno : Daiict college, Reliance Cross Rd, <br />
                Gandhinagar, Gujarat <br />
                PinCode: 382007
              </address>
              <a
                href="tel:+91 8264954234"
                className="mt-3 d-block mb-1 text-white"
              >
                +91 8264954234
              </a>
              <a
                href="mailto:devjariwala8444@gmail.com"
                className="mt-2 d-block mb-0 text-white"
              >
                devjariwala8444@gmail.com
              </a>
              <div className="social_icons d-flex align-items-center gap-30 mt-4">
                <a className="text-white" href="#">
                  <BsLinkedin className="fs-5" />
                </a>
                <a className="text-white" href="#">
                  <BsInstagram className="fs-5" />
                </a>
                <a className="text-white" href="#">
                  <BsGithub className="fs-5" />
                </a>
                <a className="text-white" href="#">
                  <BsYoutube className="fs-5" />
                </a>
              </div>
            </div>
          </div>
  
          {/* Information Links */}
          <div className="col-6 col-md-3 mb-3 mb-md-0">
            <h4 className="text-white mb-4 fs-6">Information</h4>
            <div className="footer-link d-flex flex-column">
              <Link to="/privacy-policy" className="text-white py-1">
                Privacy Policy
              </Link>
              <Link to="/refund-policy" className="text-white py-1">
                Refund Policy
              </Link>
              <Link to="/shipping-policy" className="text-white py-1">
                Shipping Policy
              </Link>
              <Link to="/term-conditions" className="text-white py-1">
                Terms & Conditions
              </Link>
              <Link className="text-white py-1">Blogs</Link>
            </div>
          </div>
  
          {/* Account Links */}
          <div className="col-6 col-md-3 mb-3 mb-md-0">
            <h4 className="text-white mb-4 fs-6">Account</h4>
            <div className="footer-link d-flex flex-column">
              <Link className="text-white py-1">About Us</Link>
              <Link className="text-white py-1">Faq</Link>
              <Link className="text-white py-1">Contact</Link>
            </div>
          </div>
  
          {/* Quick Links */}
          <div className="col-12 col-md-2">
            <h4 className="text-white mb-4 fs-6">Quick Links</h4>
            <div className="footer-link d-flex flex-column">
              <Link className="text-white py-1">Laptops</Link>
              <Link className="text-white py-1">Headphones</Link>
              <Link className="text-white py-1">Tablets</Link>
              <Link className="text-white py-1">Watch</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  
    <footer className="py-4">
      <div className="container-xxl">
        <div className="row">
          <div className="col-12">
            <p className="text-center mb-0 text-white fs-6">
              &copy; {new Date().getFullYear()}; Powered by WingsKart
            </p>
          </div>
        </div>
      </div>
    </footer>
  
    {/* Custom Media Queries */}
    <style>
      {`
        @media (max-width: 576px) {
          h4 {
            font-size: 1rem;
          }
          p, a, address {
            font-size: 0.875rem;
          }
          .social_icons a {
            font-size: 1.25rem;
          }
        }
      `}
    </style>
  </>
  
  );
};

export default Footer;
