import React, { useEffect, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import compare from "../images/compare.svg";
import wishlist from "../images/wishlist.svg";
import user from "../images/user.svg";
import cart from "../images/cart.svg";
import menu from "../images/menu.svg";
import { useDispatch, useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { getAProduct } from "../features/products/productSlilce";
import { getUserCart } from "../features/user/userSlice";

const Header = () => {
  const dispatch = useDispatch();
  const cartState = useSelector((state) => state?.auth?.cartProducts);
  const authState = useSelector((state) => state?.auth);
  const [total, setTotal] = useState(null);
  const [paginate, setPaginate] = useState(true);
  const productState = useSelector((state) => state?.product?.product);
  const navigate = useNavigate();

  const getTokenFromLocalStorage = localStorage.getItem("customer")
    ? JSON.parse(localStorage.getItem("customer"))
    : null;

  const config2 = {
    headers: {
      Authorization: `Bearer ${
        getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.token : ""
      }`,
      Accept: "application/json",
    },
  };

  useEffect(() => {
    dispatch(getUserCart(config2));
  }, []);

  const [productOpt, setProductOpt] = useState([]);
  useEffect(() => {
    let sum = 0;
    for (let index = 0; index < cartState?.length; index++) {
      sum = sum + Number(cartState[index].quantity) * cartState[index].price;
      setTotal(sum);
    }
  }, [cartState]);

  useEffect(() => {
    let data = [];
    for (let index = 0; index < productState?.length; index++) {
      const element = productState[index];
      data.push({ id: index, prod: element?._id, name: element?.title });
    }
    setProductOpt(data);
  }, [productState]);


  return (
    <>
     <header className="header-upper py-3">
  <div className="container-fluid px-5">
    <div className="row align-items-center">
      <div className="col-6 col-md-2 order-1 order-md-0">
        <h4 className="text-center">
          <Link className="text-white" to="/">
            WingsKart
          </Link>
        </h4>
      </div>

      <div className="col-12 col-md-8 order-3 order-md-1 mt-3 mt-md-0">
        <div className="input-group">
          <Typeahead
            id="pagination-example"
            onChange={(selected) => {
              navigate(`/product/${selected[0]?.prod}`);
              dispatch(getAProduct(selected[0]?.prod));
            }}
            options={productOpt}
            paginate={paginate}
            labelKey={"name"}
            placeholder="Search for Products here"
            className="w-100"
          />
          <span className="input-group-text p-3" id="basic-addon2">
            <BsSearch className="fs-6" />
          </span>
        </div>
      </div>

      {/* Icons (Wishlist, Orders, Cart, etc.) */}
      <div className="col-6 col-md-2 order-2 order-md-2 d-flex justify-content-end align-items-center">
        <div className="d-flex align-items-center justify-content-between w-100 flex-md-row">
          {/* My Orders */}
          <div className="text-center">
            <Link
              to="/my-orders"
              className="d-flex align-items-center justify-content-center gap-10 text-white"
            >
              <i className="fa-solid fa-bag-shopping"></i>
            </Link>
          </div>
          {/* Wishlist */}
          <div className="text-center">
            <Link
              to="/wishlist"
              className="d-flex align-items-center justify-content-center gap-10 text-white"
            >
              <i className="fa-solid fa-heart"></i>
            </Link>
          </div>
          {/* Contact */}
          <div className="text-center">
            <Link
              to="/contact"
              className="d-flex align-items-center justify-content-center gap-10 text-white"
            >
              <i className="fa-solid fa-headset"></i>
            </Link>
          </div>
          {/* Cart */}
          <div className="text-center">
            <Link
              to="/cart"
              className="d-flex align-items-center justify-content-center gap-10 text-white"
            >
           
                <i className="fa-solid fa-cart-shopping position-relative">
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {cartState?.length ? cartState?.length : 0}
                    <span className="visually-hidden">unread messages</span>
                  </span>
                </i>
         
            </Link>
          </div>
          {/* User Profile */}
          <div className="text-center">
            <Link
              to={authState?.user === null ? "/login" : "my-profile"}
              className="d-flex align-items-center justify-content-center gap-10 text-white"
            >
              <i className="fa-solid fa-user"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

      {productState.length > 0 && (
        <header className="header-bottom py-3">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="menu-bottom d-flex align-items-center gap-30 flex-wrap">
                  {/* <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle bg-transparent border-0 gap-15 d-flex align-items-center"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={menu} alt="menu icon" />
                      <span className="me-5 d-inline-block">Shop Categories</span>
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {productState.length > 0 &&
                        productState.map((item, index) => (
                          <li key={index}>
                            <Link className="dropdown-item text-white" to="">
                              {item?.category}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </div> */}

                  <div className="menu-links flex-grow-1">
                    <div className="d-flex align-items-center gap-15 flex-wrap justify-content-center justify-content-md-start">
                      {productState.length > 0 &&
                        productState.map((item, index) => (
                          <li key={index}>
                            <NavLink  to="">
                              {item?.category}
                            </NavLink>
                          </li>
                        ))}

                      {/* <NavLink to="/">Home</NavLink>
                      <NavLink to="/product">Our Store</NavLink>
                      <NavLink to="/my-orders">My Orders</NavLink>
                      <NavLink to="/blogs">Blogs</NavLink>
                      <NavLink to="/contact">Contact</NavLink> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
