import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";
import BlogCard from "../components/BlogCard";
import ProductCard from "../components/ProductCard";
import SpecialProduct from "../components/SpecialProduct";
import Container from "../components/Container";
import { services } from "../utils/Data";
import prodcompare from "../images/prodcompare.svg";
import wish from "../images/wish.svg";
import wishlist from "../images/wishlist.svg";
import watch from "../images/watch.jpg";
import watch2 from "../images/watch-1.avif";
import addcart from "../images/add-cart.svg";
import view from "../images/view.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogs } from "../features/blogs/blogSlice";
import moment from "moment";
import { getAllProducts } from "../features/products/productSlilce";
import ReactStars from "react-rating-stars-component";
import { addToWishlist } from "../features/products/productSlilce";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

const Home = () => {
  const blogState = useSelector((state) => state?.blog?.blog);
  const productState = useSelector((state) => state?.product?.product);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getblogs();
    getProducts();
  }, []);
  const getblogs = () => {
    dispatch(getAllBlogs());
  };

  const getProducts = () => {
    dispatch(getAllProducts());
  };

  const addToWish = (id) => {
    //alert(id);
    dispatch(addToWishlist(id));
  };
  return (
    <>
    <Container class1="home-wrapper-1 py-5">
      <div className="row">
        <div className="col-md-6 col-12 mb-4 mb-md-0">
          <div className="main-banner position-relative">
            <img
              src="images/main-banner-1.jpg"
              className="img-fluid rounded-3"
              alt="main banner"
            />
            <div className="main-banner-content position-absolute">
              <h4>SUPERCHARGED FOR PROS.</h4>
              <h5>iPad S13+ Pro.</h5>
              <p>From Rs. 81,900.00 </p>
              <Link className="button">BUY NOW</Link>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="d-flex flex-wrap gap-3 justify-content-center justify-content-md-between">
            {["catbanner-01.jpg", "catbanner-02.jpg", "catbanner-03.jpg", "catbanner-04.jpg"].map((image, index) => (
              <div className="small-banner position-relative w-100" key={index}>
                <img
                  src={`images/${image}`}
                  className="img-fluid rounded-3"
                  alt="main banner"
                />
                <div className="small-banner-content position-absolute">
                  <h4>{index % 2 === 0 ? "NEW ARRIVAL" : "BEST SALE"}</h4>
                  <h5>{index % 2 === 0 ? "Smart Devices" : "MacBook Pro"}</h5>
                  <p>From Rs. {index % 2 === 0 ? "21,625" : "1,29,900"}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  
    <Container class1="home-wrapper-2 py-5">
      <div className="row">
        <div className="col-12">
          <div className="services d-flex flex-wrap align-items-center justify-content-center gap-4">
            {services?.map((i, j) => (
              <div
                className="d-flex align-items-center gap-3 text-center text-md-start"
                key={j}
              >
                <img src={i.image} alt="services" />
                <div>
                  <h6>{i.title}</h6>
                  <p className="mb-0">{i.tagline}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  
    <Container class1="featured-wrapper py-5 home-wrapper-2">
      <div className="row">
        <div className="col-12">
          <h3 className="section-heading">Featured Collection</h3>
        </div>
        {productState &&
          productState.map((item, index) => {
            if (item.tags === "featured") {
              return (
                <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
                  <div className="product-card position-relative">
                    <div className="wishlist-icon position-absolute">
                      <button className="border-0 bg-transparent">
                        <img
                          src={wish}
                          alt="wishlist"
                          onClick={() => addToWish(item._id)}
                        />
                      </button>
                    </div>
                    <div className="product-image">
                      <img
                        src={item.images[0].url}
                        alt="product"
                        className="img-fluid"
                        onClick={() => navigate(`/product/${item._id}`)}
                      />
                    </div>
                    <div className="product-details">
                      <h6 className="brand">{item.brand}</h6>
                      <h5 className="product-title">
                        {item.title.substr(0, 70) + "..."}
                      </h5>
                      <ReactStars
                        count={5}
                        size={24}
                        value={item.totalrating.toString()}
                        edit={false}
                        activeColor="#ffd700"
                      />
                      <p className="price">Rs. {item.price}</p>
                    </div>
                  </div>
                </div>
              );
            }
          })}
      </div>
    </Container>
  
    <Container class1="famous-wrapper py-5 home-wrapper-2">
      <div className="row g-4">
        {["famous-1.webp", "famous-2.webp", "famous-3.webp", "famous-4.webp"].map((image, index) => (
          <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={index}>
            <div className="famous-card position-relative">
              <img src={`images/${image}`} className="img-fluid" alt="famous" />
              <div className="famous-content position-absolute">
                <h5 className="text-dark">Device Type {index + 1}</h5>
                <h6 className="text-dark">Product Details {index + 1}</h6>
                <p className="text-dark">From Rs. 399</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Container>
  
    <Container class1="popular-wrapper py-5 home-wrapper-2">
      <div className="row">
        <div className="col-12">
          <h3 className="section-heading">Our Popular Products</h3>
        </div>
      </div>
      <div className="row">
        {productState &&
          productState.map((item, index) => {
            if (item.tags === "popular") {
              return (
                <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
                  <div className="product-card position-relative">
                    <div className="wishlist-icon position-absolute">
                      <button className="border-0 bg-transparent">
                        <img
                          src={wish}
                          alt="wishlist"
                          onClick={() => addToWish(item._id)}
                        />
                      </button>
                    </div>
                    <div className="product-image">
                      <img
                        src={item.images[0].url}
                        alt="product"
                        className="img-fluid"
                        onClick={() => navigate(`/product/${item._id}`)}
                      />
                    </div>
                    <div className="product-details">
                      <h6 className="brand">{item.brand}</h6>
                      <h5 className="product-title">
                        {item.title.substr(0, 70) + "..."}
                      </h5>
                      <ReactStars
                        count={5}
                        size={24}
                        value={item.totalrating.toString()}
                        edit={false}
                        activeColor="#ffd700"
                      />
                      <p className="price">Rs. {item.price}</p>
                    </div>
                  </div>
                </div>
              );
            }
          })}
      </div>
    </Container>
  
    <Container class1="marque-wrapper home-wrapper-2 py-5">
      <div className="row">
        <div className="col-12">
          <div className="marquee-inner-wrapper card-wrapper">
            <Marquee className="d-flex gap-4 justify-content-center">
              {Array.from({ length: 8 }).map((_, index) => (
                <div className="mx-4 w-25" key={index}>
                  <img src={`images/brand-0${index + 1}.png`} alt="brand" />
                </div>
              ))}
            </Marquee>
          </div>
        </div>
      </div>
    </Container>
  </>
  
  );
};

export default Home;
